import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/seo"
import Layout from "../components/layout"

const Sended_email = () => {
    const data = useStaticQuery(graphql`
        query {
            image1: file(relativePath: { eq: "Chat_PNG.png" }) {
                childImageSharp {
                    fluid(maxWidth: 900) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    let color = 'text-success';
    let textBtn = 'Regresar';

    return (
        <Layout>
            <SEO title="Contacto" />

            {/*Quitar clase ftco-animate, que es usada para las animaciones con jquery*/ }

            <section data-sal="fade" data-sal-duration="400" data-sal-easing="ease" className="home-slider ftco-degree-bg">
                <div className="slider-item">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row slider-text align-items-center justify-content-center">
                            <div data-sal="slide-up" data-sal-duration="400" data-sal-easing="ease" className="col-md-6 mt-5 col-sm-12 text-center">
                                <p class="breadcrumbs d-none d-sm-none d-md-block">
                                    <span class="mr-2"><Link swipe to="/">Inicio</Link></span> <span>Contacto</span>
                                </p>
                                <h3 class={`mb-3 bread mt-4 `+color} >Su solicitud fue enviada exitosamente. Nuestros Representantes lo contactarán en el menor tiempo posible</h3>
                                <p class="mb-3 bread mt-4">Gracias por tu confianza</p>
                                <p class="mb-3 bread mt-4" style={{display: 'inline-flex'}}>

                                    <Link
                                        className="btn btn_white px-5 py-3 mt-3"
                                        to="/#contact-section"
                                        >
                                        {textBtn}
                                    </Link>
                                </p>
                            </div>
                            <div data-sal="slide-up" data-sal-duration="400" data-sal-easing="ease" className="col-md-5 col-sm-12 text-center mt-5 pt-5">
                                <Img fluid={data.image1.childImageSharp.fluid} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Sended_email
